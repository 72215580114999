import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import {
  Button,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { toast } from 'react-toastify';
import axios from 'axios';
import Hero from '../../images/HERO-CS3.svg';
import { Header, HeroImg, NotesCont, NotesWrapper } from './userStyles';
import 'react-toastify/dist/ReactToastify.css';
import useCountdownTimer from '../../hooks/useCountdownTimer';
import useAuthState from '../../stores/auth';

function AppointmentItem({ appointment }) {
  const { formattedRemainingTime } = useCountdownTimer(
    new Date(appointment.date),
  );
  return (
    <TableRow>
      <TableCell>{formattedRemainingTime}</TableCell>
      <TableCell>{appointment.type}</TableCell>
      <TableCell>{appointment.staff.fullName}</TableCell>
      {appointment.type === 'online' && (
        <TableCell>
          <Button onClick={() => navigate(`/meeting/${appointment._id}`)}>
            view
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
}

export default function AppointmentTab() {
  const { user } = useAuthState();
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const fetchUserAppointments = async () => {
    try {
      setLoading(true);
      const result = await axios.get(
        `${process.env.GATSBY_API_URL}/users/${user.id}/appointments?page=${page}&limit=0`
      );
      const { data } = result;

      data.data.sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();

        if (dateA < Date.now() && dateB < Date.now()) {
          return dateA - dateB;
        }
        if (dateA < Date.now()) {
          return 1;
        }
        if (dateB < Date.now()) {
          return -1;
        }
        return dateA - dateB;
      });

      setAppointments(data.data);
      const { paging } = result.data;

      setTotalPages(paging.pages);
      setLoading(false);
    } catch (err) {
      console.log('🚀 ~ file: [doctorId].js:81 ~ fetchUserAppointments ~ err:', err);
      setLoading(false);
      const { message } = err.response.data;
      toast.error(message);
    }
  };

  const fetchPage = (event, value) => {
    setPage(value);
    fetchUserAppointments();
  };

  useEffect(() => {
    if (user && Object.keys(user).length !== 0) {
      fetchUserAppointments();
    }
  }, [user]);

  return (
    <NotesCont>
      <NotesWrapper>
        {/* <HeroImg src={Hero} alt="Hero" /> */}
        <Header>Your Appointments</Header>
        <TableContainer
          style={{
            width: '100%',
            overflowX: 'auto', // Handle horizontal overflow
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appointments.length > 0 ? (
                appointments.map(appointment => (
                  <AppointmentItem
                    key={appointment._id}
                    appointment={appointment}
                  />
                ))
              ) : (
                <>
                  <HeroImg src={Hero} alt="Hero" />
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No appointments found
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
          {totalPages > 1 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '10px 0',
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={fetchPage}
                color="primary"
                shape="rounded"
              />
            </div>
          )}
        </TableContainer>
      </NotesWrapper>
    </NotesCont>
  );
}
