import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  Container,
  OrderTabWrapper,
  OrderTabInfo,
  OrderTabProduct2,
  ProductDetail2,
  Details2,
  ProductName,
  ProductId,
  ProductSize,
  SetReminderButton,
} from '../../style/cartstyles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Modal from '@material-ui/core/Modal'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { format } from 'date-fns'
import useAuthState from '../../stores/auth'
import { LuPen } from "react-icons/lu"
import { MdDelete } from "react-icons/md"


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  backgroundColor: '#fff',
  border: '2px solid #000',
  boxShadow: 24,
  padding: 20,
  boxSizing: 'border-box',
  borderRadius: 10,
  maxHeight: '80vh',
  overflowY: 'auto',
}

export default function MedsTab() {
  const [orders, setOrders] = useState([])
  const [pres, setPres] = useState([])
  const [userMedications, setUserMedications] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [currentTab, setCurrentTab] = useState(1)
  const [reminders, setReminders] = useState({})
  const [reminderModalOpen, setReminderModalOpen] = useState(false)
  const [medicationModalOpen, setMedicationModalOpen] = useState(false)
  const [selectedMedication, setSelectedMedication] = useState(null)
  const [newMedication, setNewMedication] = useState({
    productName: '',
    genericName: '',
    category: '',
    dosage: '',
    route: '',
    frequency: '',
    duration: '',
    comment: '',
    regardsToMeal: '',
    reason: '',
    startDate: '',
    endDate: '',
    instructions: '',
  })
  const [newReminders, setNewReminders] = useState([])

  const { user } = useAuthState()

  useEffect(() => {
    if (user && Object.keys(user).length !== 0) {
      fetchOrders()
      fetchPres()
      fetchUserMedications()
    }
  }, [user])

  const fetchOrders = async () => {
    try {
      const result = await axios.get(
        `${process.env.GATSBY_API_URL}/users/${user.id}/orders`,
      )
      console.log('Orders:', result.data.data)
      setOrders(result.data.data)
    } catch (err) {
      setError('Failed to fetch orders')
    }
  }

  const fetchPres = async () => {
    try {
      const result = await axios.get(
        `${process.env.GATSBY_API_URL}/users/${user.id}/prescriptions`,
      )
      console.log('Prescription:', result.data.data)
      setPres(result.data.data)
    } catch (err) {
      setError('Failed to fetch prescriptions')
    } finally {
      setLoading(false)
    }
  }

  const fetchUserMedications = async () => {
    try {
      const result = await axios.get(
        `${process.env.GATSBY_API_URL}/users/${user.id}/userMedications`,
      )
      console.log('Own:', result.data.data)
      setUserMedications(result.data.data)
    } catch (err) {
      setError('Failed to fetch user medications')
    }
  }

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const getCustomBrandName = item => {
    return `${item.product.productName} ${
      item.product.formulation !== 'not applicable'
        ? item.product.formulation
        : ''
    } ${
      item.product.strength !== 'not applicable' ? item.product.strength : ''
    } ${item.product.packSize}`
  }


      // Unwind the pres collection so each product becomes its own document
    const unwoundPres = pres.flatMap(presItem => 
      presItem.product.map(productItem => ({
        ...presItem,
        product: productItem  // Replace the product array with the individual product object
      }))
    );

    // Combine the orders and unwound pres collections
    const orderPres = [...orders, ...unwoundPres].sort(
      (a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt)
    );

    console.log("orderPres", orderPres);


  const addReminderTime = () => {
    setReminders(prevReminders => ({
      ...prevReminders,
      [selectedMedication._id]: [
        ...(prevReminders[selectedMedication._id] || []),
        { reminderTime: '', repeat: '' },
      ],
    }))
  }

  const handleReminderChange = (index, field, value) => {
    if (index < reminders[selectedMedication._id].length) {
      setReminders(prevReminders => ({
        ...prevReminders,
        [selectedMedication._id]: prevReminders[selectedMedication._id].map(
          (reminder, i) =>
            i === index ? { ...reminder, [field]: value || '' } : reminder,
        ),
      }))
    } else {
      setNewReminders(
        newReminders.map((reminder, i) =>
          i === index - reminders[selectedMedication._id].length
            ? { ...reminder, [field]: value || '' }
            : reminder,
        ),
      )
    }
  }

  const handleSetReminder = async () => {
    const medicationReminders = [
      ...reminders[selectedMedication._id],
      ...newReminders,
    ]

    if (!medicationReminders || !medicationReminders[0].reminderTime) {
      setError('Please specify a reminder time.')
      return
    }

    const source = selectedMedication.source || 'user'

    try {
      for (const reminder of medicationReminders) {
        await axios.post(
          `${process.env.GATSBY_API_URL}/reminderRoutes/set-reminder`,
          {
            userId: user.id,
            medicationId: selectedMedication._id,
            reminderTime: reminder.reminderTime,
            repeat: reminder.repeat || '',
            source,
          },
        )
      }

      alert('Reminders set successfully!')
      setError('')
      setReminderModalOpen(false)
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message)
      } else {
        setError('Failed to set Reminder, Check your date!')
      }
    }
  }

  const formatDateForInput = dateString => {
    const date = new Date(dateString)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    return `${year}-${month}-${day}T${hours}:${minutes}`
  }

  const openReminderModal = async (medication, source = 'order') => {
    setSelectedMedication({ ...medication, source })

    try {
      const reminderResult = await axios.get(
        `${process.env.GATSBY_API_URL}/reminderRoutes/reminders/${user.id}/${medication._id}`,
      )
      const fetchedReminders = reminderResult.data.reminders || []

      const formattedReminders = fetchedReminders.map(reminder => ({
        ...reminder,
        reminderTime: reminder.reminderTime
          ? formatDateForInput(reminder.reminderTime)
          : '',
      }))

      setReminders({
        [medication._id]:
          formattedReminders.length > 0
            ? formattedReminders
            : [{ reminderTime: '', repeat: '' }],
      })

      setNewReminders([])
    } catch (err) {
      console.error('Failed to fetch reminders:', err)
      setReminders({
        [medication._id]: [{ reminderTime: '', repeat: '' }],
      })
    }

    setReminderModalOpen(true)
  }

  const openUserReminderModal = async (medication, source = 'user') => {
    setSelectedMedication({ ...medication, source })

    try {
      const reminderResult = await axios.get(
        `${process.env.GATSBY_API_URL}/reminderRoutes/reminders/${user.id}/${medication._id}`,
      )
      const fetchedReminders = reminderResult.data.reminders || []

      const formattedReminders = fetchedReminders.map(reminder => ({
        ...reminder,
        reminderTime: reminder.reminderTime
          ? formatDateForInput(reminder.reminderTime)
          : '',
      }))

      setReminders({
        [medication._id]:
          formattedReminders.length > 0
            ? formattedReminders
            : [{ reminderTime: '', repeat: '' }],
      })

      setNewReminders([])
    } catch (err) {
      console.error('Failed to fetch reminders:', err)
      setReminders({
        [medication._id]: [{ reminderTime: '', repeat: '' }],
      })
    }

  setReminderModalOpen(true);
}; 

  const closeReminderModal = () => {
    setReminderModalOpen(false);
    setSelectedMedication(null);
  };

  const deleteReminder = async (userId, reminderId) => {
    try {
      await axios.delete(`${process.env.GATSBY_API_URL}/reminderRoutes/reminder/${user.id}/${reminderId}`);
      console.log('Reminder deleted successfully');

      alert('Reminder deleted successfully');
  
      openReminderModal(selectedMedication, selectedMedication.source);
    } catch (err) {
      console.error('Failed to delete reminder:', err);
    }
  };
  

  const openMedicationModal = (medication = null, category = '') => {
    if (medication) {
      setSelectedMedication(medication)
      setNewMedication({
        productName: medication.productName || '',
        genericName: medication.genericName || '',
        category: medication.category || category,
        dosage: medication.dosage || '',
        route: medication.route || '',
        frequency: medication.frequency || '',
        duration: medication.duration || '',
        comment: medication.comment || '',
        regardsToMeal: medication.regardsToMeal || '',
        reason: medication.reason || '',
        startDate: medication.startDate || '',
        endDate: medication.endDate || '',
        instructions: medication.instructions || '',
      })
    } else {
      setSelectedMedication(null)
      setNewMedication({
        productName: '',
        genericName: '',
        category: category || '',
        dosage: '',
        route: '',
        frequency: '',
        duration: '',
        comment: '',
        regardsToMeal: '',
        reason: '',
        startDate: '',
        endDate: '',
        instructions: '',
      })
    }
    setMedicationModalOpen(true)
  }

  const closeMedicationModal = () => {
    setMedicationModalOpen(false)
  }

  const handleMedicationChange = (field, value) => {
    setNewMedication({
      ...newMedication,
      [field]: value,
    })
  }

  const handleAddMedication = async () => {
    try {
      const result = await axios.post(
        `${process.env.GATSBY_API_URL}/users/${user.id}/userMedications`,
        newMedication,
      )
      alert('Medication added successfully!')
      setUserMedications([...userMedications, result.data.data])
      closeMedicationModal()
    } catch (err) {
      alert('Failed to add medication.')
    }
  }

  const handleUpdateMedication = async () => {
    if (!selectedMedication || !selectedMedication._id) {
      console.error('Selected medication or medication ID is missing.')
      alert('Oops! Something Happened, Refresh the page and Try Again')
      return
    }

    try {
      const url = `${process.env.GATSBY_API_URL}/users/${user.id}/userMedications/${selectedMedication._id}`
      console.log('Update URL:', url)
      console.log('Selected Medication:', selectedMedication)
      console.log('Update data:', newMedication)

      const response = await axios.put(url, newMedication)
      console.log('API Response:', response)

      alert('Medication updated successfully!')

      setUserMedications(
        userMedications.map(med =>
          med._id === selectedMedication._id
            ? { ...med, ...newMedication }
            : med,
        ),
      )
      closeMedicationModal()
    } catch (err) {
      console.error(
        'Error updating medication:',
        err.response ? err.response.data : err.message,
      )
      alert('Oops! Something Happened, Refresh the page and Try Again')
    }
  }

  const handleDeleteMedication = async medicationId => {
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this medication?',
    )
    if (!isConfirmed) {
      return
    }

    if (!medicationId) {
      console.error('Medication ID is missing.')
      alert('Oops! Something Happened, Refresh the page and Try Again')
      return
    }

    console.log('User ID:', user.id)
    console.log('Medication ID:', medicationId)
    console.log(
      `API URL: ${process.env.GATSBY_API_URL}/users/${user.id}/userMedications/${medicationId}`,
    )

    try {
      await axios.delete(
        `${process.env.GATSBY_API_URL}/users/${user.id}/userMedications/${medicationId}`,
      )
      alert('Medication deleted successfully!')
      setUserMedications(
        userMedications.filter(med => med._id !== medicationId),
      )
    } catch (err) {
      console.error('Error deleting medication:', err)
      alert('Oops! Something Happened, Refresh the page and Try Again')
    }
  };

  const hasValidReminderTime = () => {
    return (reminders[selectedMedication?._id] || []).some(reminder => reminder.reminderTime);
  };
  
  

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab label="Antibiotics" key="Antibiotics" />
          <Tab label="All medication" key="all" />
        </Tabs>
      </div>

      {loading && (
        <div
          style={{
            border: '2px solid green',
            borderRadius: '10%',
            width: '100px',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '10px',
          }}
        >
          Loading
        </div>
      )}

              <Modal
                open={reminderModalOpen}
                onClose={closeReminderModal}
                aria-labelledby="set-reminder-modal"
                aria-describedby="set-reminder-description"
              >
                <div style={modalStyle}>
                  <h2 id="set-reminder-modal">Set Reminder</h2>
                  {error && <div style={{ color: 'red', textAlign: 'center' }}>{error}</div>}
                  {(reminders[selectedMedication?._id] || []).map((reminder, index) => (
                    <div key={index} style={{ marginBottom: "15px", position: "relative" }}>
                      <TextField
                        label="Reminder Time"
                        type="datetime-local"
                        value={reminder.reminderTime || ""}
                        onChange={(e) => handleReminderChange(index, "reminderTime", e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                      <TextField
                        select
                        label="Repeat"
                        value={reminder.repeat || ""}
                        onChange={(e) => handleReminderChange(index, "repeat", e.target.value)}
                        SelectProps={{ native: true }}
                        fullWidth
                      >
                        <option value=""></option>
                        <option value="daily">Every Day</option>
                        <option value="weekly">Every Week</option>
                        <option value="monthly">Every Month</option>
                      </TextField>
                      {/* Delete button */}
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={() => deleteReminder(selectedMedication._id, reminder._id)}
                        style={{ top: 0, right: 0, margin: "8px" }}
                      >
                        Delete
                      </Button>
                    </div>
                  ))}
                  <Button variant="outlined" color="primary" onClick={addReminderTime}>
                    Add Another Reminder
                  </Button>
                  <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "flex-end", gap: "10px" }}>
                    <Button variant="outlined" onClick={closeReminderModal} style={{ flexShrink: 0 }}>
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleSetReminder}
                      style={{ flexShrink: 0 }}
                      disabled={!hasValidReminderTime()}
                    >
                      Set Reminders
                    </Button>

                  </div>
                </div>
              </Modal>




      <Modal
        open={medicationModalOpen}
        onClose={closeMedicationModal}
        aria-labelledby="medication-modal"
        aria-describedby="medication-description"
      >
        <div style={modalStyle}>
          <h2 id="medication-modal">
            {selectedMedication ? 'Edit Medication' : 'Add Medication'}
          </h2>
          <TextField
            label="Product Name"
            value={newMedication.productName}
            onChange={e =>
              handleMedicationChange('productName', e.target.value)
            }
            fullWidth
            style={{ marginBottom: '15px' }}
          />
          <TextField
            label="Generic Name"
            value={newMedication.genericName}
            onChange={e =>
              handleMedicationChange('genericName', e.target.value)
            }
            fullWidth
            style={{ marginBottom: '15px' }}
          />
          <TextField
            label="Category"
            value={newMedication.category}
            onChange={e => handleMedicationChange('category', e.target.value)}
            fullWidth
            style={{ marginBottom: '15px' }}
          />
          <TextField
            label="Dosage"
            value={newMedication.dosage}
            onChange={e => handleMedicationChange('dosage', e.target.value)}
            fullWidth
            style={{ marginBottom: '15px' }}
          />
          <TextField
            label="Route"
            value={newMedication.route}
            onChange={e => handleMedicationChange('route', e.target.value)}
            fullWidth
            style={{ marginBottom: '15px' }}
          />
          <TextField
            label="Frequency"
            value={newMedication.frequency}
            onChange={e => handleMedicationChange('frequency', e.target.value)}
            fullWidth
            style={{ marginBottom: '15px' }}
          />
          <TextField
            label="Duration"
            value={newMedication.duration}
            onChange={e => handleMedicationChange('duration', e.target.value)}
            fullWidth
            style={{ marginBottom: '15px' }}
          />
          <TextField
            label="Comment"
            value={newMedication.comment}
            onChange={e => handleMedicationChange('comment', e.target.value)}
            fullWidth
            style={{ marginBottom: '15px' }}
          />
          <TextField
            label="Regards To Meal"
            value={newMedication.regardsToMeal}
            onChange={e =>
              handleMedicationChange('regardsToMeal', e.target.value)
            }
            fullWidth
            style={{ marginBottom: '15px' }}
          />
          <TextField
            label="Reason"
            value={newMedication.reason}
            onChange={e => handleMedicationChange('reason', e.target.value)}
            fullWidth
            style={{ marginBottom: '15px' }}
          />
          <TextField
            label="Start Date"
            type="date"
            value={newMedication.startDate}
            onChange={e => handleMedicationChange('startDate', e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ marginBottom: '15px' }}
          />
          <TextField
            label="End Date"
            type="date"
            value={newMedication.endDate}
            onChange={e => handleMedicationChange('endDate', e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ marginBottom: '15px' }}
          />
          <TextField
            label="Instructions"
            value={newMedication.instructions}
            onChange={e =>
              handleMedicationChange('instructions', e.target.value)
            }
            fullWidth
            style={{ marginBottom: '15px' }}
          />
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            {selectedMedication && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleDeleteMedication(selectedMedication._id)}
                style={{ flexShrink: 0 }}
              >
                Delete
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={closeMedicationModal}
              style={{ flexShrink: 0 }}
            >
              Cancel
            </Button>
            {selectedMedication ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleUpdateMedication}
                style={{ flexShrink: 0 }}
              >
                Update Medication
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddMedication}
                style={{ flexShrink: 0 }}
              >
                Add Medication
              </Button>
            )}
          </div>
        </div>
      </Modal>

      <OrderTabWrapper>
        <OrderTabInfo>
          {currentTab === 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginTop: '10px' }}>
                  <SetReminderButton
                    variant="contained"
                    color="primary"
                    onClick={() => openMedicationModal(null, 'antibiotics')}
                  >
                    Add Medication
                  </SetReminderButton>
                </div>
                <OrderTabProduct2>
                  {orders.length === 0 && <div>No records found</div>}
                    {orders.map((order, index) => (
                      <ProductDetail2 key={index}>
                        {order.processed &&
                          // order.transactionId &&
                          // order.orderStatus === 'received' &&
                          order.product.category.includes('antibiotics') && (
                            <Details2>
                              <ProductName>
                                <b>Product: </b> {getCustomBrandName(order)}
                              </ProductName>
                              <ProductId>
                                <b>Generic Name:</b> {order.product.genericName}
                              </ProductId>

                              <ProductSize>
                                <b>How to use it:</b>{' '}
                                {order.product.comment ||
                                  'Your Doctor to indicate'}{' '}
                                <br />
                                <b>Why use it:</b>{' '}
                                {order.diagnosis || 'Your Doctor to indicate'}
                                <br />
                                <b>Date Dispensed: </b>{' '}
                                {format(
                                  new Date(order.updatedAt),
                                  'yyyy-MM-dd hh:mm aa',
                                )}
                                <br />
                                <b>Date Started: </b>{' '}
                                {format(
                                  new Date(order.updatedAt),
                                  'yyyy-MM-dd hh:mm aa',
                                )}
                                <br />
                                <b>Date Stopped: </b>
                              </ProductSize>

                              <SetReminderButton
                                onClick={() => openReminderModal(order.product)}
                              >
                                Set Reminder
                              </SetReminderButton>
                            </Details2>
                          )}
                      </ProductDetail2>
                    ))}
                    {userMedications
                      .filter(
                        medication => medication.category === 'antibiotics',
                      )
                      .map((medication, index) => (
                        <ProductDetail2 key={index}>
                          <Details2>
                            <ProductName>
                              <b>Product: </b> {medication.productName}
                            </ProductName>
                            <ProductId>
                              <b>Generic Name:</b> {medication.genericName}
                            </ProductId>

                            <ProductSize>
                              <b>How to use it:</b>{' '}
                              {medication.comment || 'Your Doctor to indicate'}{' '}
                              <br />
                              <b>Why use it:</b>{' '}
                              {medication.diagnosis ||
                                'Your Doctor to indicate'}{' '}
                              <br />
                              <b>Date Started: </b>{' '}
                              {medication.startDate
                                ? format(
                                    new Date(medication.startDate),
                                    'yyyy-MM-dd',
                                  )
                                : 'Not specified'}{' '}
                              <br />
                              <b>Date Ended: </b>{' '}
                              {medication.endDate
                                ? format(
                                    new Date(medication.endDate),
                                    'yyyy-MM-dd',
                                  )
                                : 'Not specified'}{' '}
                              <br />
                              <b>Date Stopped: </b>
                            </ProductSize>

                            {/* <SetReminderButton
                              onClick={() => openUserReminderModal(medication)}
                            >
                              Set Reminder
                            </SetReminderButton> */}

                            <div
                              style={{
                                marginTop: '10px',
                                display: 'flex',
                                gap: '10px',
                                width: '150px',
                              }}
                            >
                              <SetReminderButton
                              onClick={() => openUserReminderModal(medication)}
                            >
                              Set Reminder
                            </SetReminderButton>
                              <Button
                                //variant="outlined"
                                color="primary"
                                onClick={() => openMedicationModal(medication)}
                              >
                                {/* Update */}
                                <LuPen size={25}/>
                              </Button>
                              <Button
                                //variant="outlined"
                                color="secondary"
                                onClick={() =>
                                  handleDeleteMedication(medication._id)
                                }
                              >
                                {/* Delete */}
                                <MdDelete size={25}/>
                              </Button>
                            </div>
                          </Details2>
                        </ProductDetail2>
                      ))}
                 
                </OrderTabProduct2>
              </div>
            </>
          )}
          {currentTab === 1 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                                                   
                }}
              >
                <div
                  style={{
                    marginTop: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <SetReminderButton
                    variant="contained"
                    color="primary"
                    onClick={() => openMedicationModal(null)}
                  >
                    Add Medication
                  </SetReminderButton>
                </div>
                <OrderTabProduct2>
                  {unwoundPres.length === 0 && userMedications.length === 0 && (
                      <div>No records found</div>
                    )}
                    {unwoundPres.map((order, index) => (
                      <ProductDetail2 key={index}>
                        {
                        order.processed &&
                          // order.transactionId &&
                          // order.orderStatus === 'received' && 
                          (
                            <Details2>
                              <ProductName>
                                <b>Product: </b> {getCustomBrandName(order)}
                              </ProductName>
                              <ProductId>
                                <b>Generic Name:</b> {order.product.genericName}
                              </ProductId>

                              <ProductSize>
                                <b>How to use it:</b>{' '}
                                {order.product.comment ||
                                  'Your Doctor to indicate'}{' '}
                                <br />
                                <b>Why use it:</b>{' '}
                                {order.diagnosis || 'Your Doctor to indicate'}
                                <br />
                                <b>Date Dispensed: </b>{' '}
                                {format(
                                  new Date(order.updatedAt),
                                  'yyyy-MM-dd hh:mm aa',
                                )}
                                <br />
                                <b>Date Started: </b>{' '}
                                {format(
                                  new Date(order.updatedAt),
                                  'yyyy-MM-dd hh:mm aa',
                                )}
                                <br />
                                <b>Date Stopped: </b>
                              </ProductSize>

                              <SetReminderButton
                                onClick={() => openReminderModal(order.product)}
                              >
                                Set Reminder
                              </SetReminderButton>
                            </Details2>
                          )}
                      </ProductDetail2>
                    ))}
                    {userMedications.map((medication, index) => (
                      <ProductDetail2 key={index}>
                        <Details2>
                          <ProductName>
                            <b>Product: </b> {medication.productName}
                          </ProductName>
                          <ProductId>
                            <b>Generic Name:</b> {medication.genericName}
                          </ProductId>

                          <ProductSize>
                            <b>How to use it:</b>{' '}
                            {medication.comment || 'Your Doctor to indicate'}{' '}
                            <br />
                            <b>Why use it:</b>{' '}
                            {medication.diagnosis || 'Your Doctor to indicate'}{' '}
                            <br />
                            <b>Date Started: </b>{' '}
                            {medication.startDate
                              ? format(
                                  new Date(medication.startDate),
                                  'yyyy-MM-dd',
                                )
                              : 'Not specified'}{' '}
                            <br />
                            <b>Date Ended: </b>{' '}
                            {medication.endDate
                              ? format(
                                  new Date(medication.endDate),
                                  'yyyy-MM-dd',
                                )
                              : 'Not specified'}{' '}
                            <br />
                            <b>Date Stopped: </b>
                          </ProductSize>           

                          <div
                            style={{
                              marginTop: '10px',
                              display: 'flex',
                              gap: '10px',
                              width: '150px',
                            }}
                          >
                            <SetReminderButton
                            onClick={() => openUserReminderModal(medication)}
                          >
                            Set Reminder
                          </SetReminderButton>
                            <Button
                              //variant="outlined"
                              color="primary"
                              onClick={() => openMedicationModal(medication)}
                            >
                              {/* Update */}
                              <LuPen size={25}/>
                            </Button>
                            <Button
                              //variant="outlined"
                              color="secondary"
                              onClick={() =>
                                handleDeleteMedication(medication._id)
                              }
                            >
                              {/* Delete */}
                              <MdDelete size={25}/>
                            </Button>
                          </div>
                        </Details2>
                      </ProductDetail2>
                    ))}
                </OrderTabProduct2>
              </div>
            </>
          )}
        </OrderTabInfo>
      </OrderTabWrapper>
    </Container>
  )
}
